<template>
  <div>
    <!-- TARIFAS -->
    <section id="tarifas">
      <div class="">
        <div class="row">
          <div class="col-md-5 col-sm-6 col-md-offset-1 text-left">
            <div class="title" itemscope itemtype="https://handelbay.com/">
              <h2 class="title-1" itemprop="HandelBay Innvador">
                {{ $t('lang.rates.rates_and_costs') }}
              </h2>
              <h3 class="title-2" itemprop="HandelBay en tu día a día">
                {{ $t('lang.rates.HandelBay') }}
              </h3>
              <span class="handelbay-active"></span>
            </div>
          </div>
        </div>
        <!-- .row -->
        <!-- .handelbay-background-curva -->
        <div id="handelbay-background-curva">
        </div>
        <!-- .handelbay-background-curva -->
        <div class="handelbay-content-planes">
          <div class="container">
            <div class="row ">
              <div class="col-md-10 col-md-offset-1">
                <div class="handelbay-progress-title text-center">
                  <h2 class="title-plan">
                    {{ $t('lang.rates.your_profile_and_your_plan') }}
                  </h2>
                </div>
                <div>
                  <div class="tabs-handelbay-planes text-center">
                    <!-- Nav tabs -->
                    <ul class="nav nav-tabs" role="tablist">
                      <li role="presentation" class="buyer">
                        <a href="#buyer" aria-controls="buyer" role="tab" data-toggle="tab" onclick="change_plan(1);">
                        {{ $t('lang.rates.buyer') }}
                      </a>
                      </li>
                      <li role="presentation" class="active dual">
                        <a href="#dual" aria-controls="dual" role="tab" data-toggle="tab" @click="change_plan(2); active_p_b();">
                        {{ $t('lang.rates.dual') }}
                      </a>
                      </li>
                      <li role="presentation" class="provider" id="prov">
                        <a href="#provider" aria-controls="provider" role="tab" data-toggle="tab" onclick="change_plan(3);">
                        {{ $t('lang.rates.provider') }}
                      </a>
                      </li>
                      <input type="hidden" value="2" id="type_user">                                       
                    </ul>
                  </div>
                  <!-- Tab panes -->
                  <div class="tab-content">
                    <div class="months_plan months text-center">
                      <p class="time_licence text-center">
                        {{ $t('lang.rates.leave_time') }}
                      </p>
                      <button class="btn-time mes" @click="change_month(1)" >
                        <span>
                          {{ $t('lang.rates.1_month') }}
                        </span>
                      </button>
                      <button class="btn-time semestre" @click="change_month(2)">
                        <span>
                          {{ $t('lang.rates.6_months') }}
                        </span> 
                      </button>
                      <button class="btn-time anual active" @click="change_month(3)">
                        <span>
                          {{ $t('lang.rates.12_months') }}
                        </span>
                      </button>
                    </div>
                    <div role="tabpanel" class="tab-pane active" id="buyer">
                      <div class="title-tab text-center title-type-plan">
                        <p class="text-center">
                          {{ $t('lang.rates.buyer_profil') }}
                        </p>
                      </div>
                      <hr class="hr-space-planes">
                      <h2 style="text-align:center; font-family: 'gotham_bold';">
                        <a :href="'https://web.whatsapp.com/send?phone=' + global.indicative + global.cellphone">
                          <i class="fa fa-whatsapp" style="color:green"></i>
                          {{global.cellphone}}
                        </a>
                      </h2>
                      <div class="row-handelbay-plannes buyer-row">
                        <div class="col-plan-handelbay-1">
                          <div class="item text-center">
                            <strong>
                              <h4 class="plan_buyer_b" style="font-family: 'gotham_bold';">
                              {{ $t('lang.rates.basic') }}
                            </h4>
                            </strong>
                            <br>
                            <strong>
                              {{ $t('lang.rates.1_user') }}
                            </strong>
                            <ul>
                              <li class="first-li">
                                <a href="javascript:void(0)">
                                  <strong>
                                    {{ $t('lang.rates.5_processes_/_month') }}
                                  </strong>
                                </a>
                              </li>
                              <li class="next-li buyer">
                                <a href="javascript:void(0)">
                                  <strong>
                                    {{ $t('lang.rates.RFQ_request') }}
                                  </strong>
                                  <i class="icon dripicons-checkmark point"></i>
                                </a>
                              </li>
                              <li class="next-li buyer">
                                <a href="javascript:void(0)">
                                  <strong>
                                    {{ $t('lang.rates.comparisons') }}
                                  </strong>
                                  <i class="icon dripicons-checkmark point"></i>
                                </a>
                              </li>
                              <li class="next-li x">
                                <a href="javascript:void(0)">
                                  <strong>
                                    {{ $t('lang.rates.tracker') }}
                                  </strong>
                                  <i class="icon dripicons-plus point"></i>
                                </a>
                              </li>
                              <li class="next-li x">
                                <a href="javascript:void(0)">
                                  <strong>
                                    {{ $t('lang.rates.reports') }}
                                  </strong>
                                  <i class="icon dripicons-plus point"></i>
                                </a>
                              </li>
                              <li class="next-li x">
                                <a href="javascript:void(0)">
                                  <strong>
                                    {{ $t('lang.rates.transfers') }}
                                  </strong>
                                  <i class="icon dripicons-plus point"></i>
                                </a>
                              </li>
                              <li class="next-li x">
                                <a href="javascript:void(0)">
                                  <strong>
                                    {{ $t('lang.rates.approvals') }}
                                  </strong>
                                  <i class="icon dripicons-plus point"></i>
                                </a>
                              </li>
                              <li class="next-li x">
                                <a href="javascript:void(0)">
                                  <strong>
                                    {{ $t('lang.rates.download_data') }}
                                  </strong>
                                  <i class="icon dripicons-plus point"></i>
                                </a>
                              </li>
                              <li class="next-li x">
                                <a href="javascript:void(0)">
                                  <strong>
                                    {{ $t('lang.rates.validation') }}
                                  </strong>
                                  <i class="icon dripicons-plus point"></i>
                                </a>
                              </li>
                              <li class="next-li x">
                                <a href="javascript:void(0)">
                                  <strong>
                                    {{ $t('lang.rates.user_areas') }}
                                  </strong>
                                  <i class="icon dripicons-plus point"></i>
                                </a>
                              </li>
                              <li class="next-li x">
                                <a href="javascript:void(0)">
                                  <strong>
                                    {{ $t('lang.rates.integration_with_ERP') }}
                                  </strong>
                                  <i class="icon dripicons-plus point"></i>
                                </a>
                              </li>
                              <li class="next-li x">
                                <a href="javascript:void(0)">
                                  <strong>
                                    {{ $t('lang.rates.RFI_request') }}
                                    </strong>
                                  <i class="icon dripicons-plus point"></i>
                                </a>
                              </li>
                            </ul>
                          </div>
                          <div class="handelbay-buttons">
                            <a :href="global.sign_up" class="btn btn-handelbay-planes">
                              {{ $t('lang.rates.i_want_this_plan') }}
                            </a>
                          </div>
                        </div>
                        <div class="col-plan-handelbay-1">
                          <div class="item text-center">
                            <strong>
                              <h4 class="plan_buyer_b" style="font-family: 'gotham_bold';">
                              {{ $t('lang.rates.single') }}
                            </h4>
                            </strong>
                            <br>
                            <strong>{{ $t('lang.rates.1_user') }}</strong>
                            <ul>
                              <li class="first-li">
                                <a href="javascript:void(0)">
                                  <strong>
                                    {{ $t('lang.rates.10_processes_/_month') }}
                                  </strong>
                                </a>
                              </li>
                              <li class="next-li buyer">
                                <a href="javascript:void(0)">
                                  <strong>{{ $t('lang.rates.RFQ_request') }}</strong>
                                  <i class="icon dripicons-checkmark point"></i>
                                </a>
                              </li>
                              <li class="next-li buyer">
                                <a href="javascript:void(0)">
                                  <strong>{{ $t('lang.rates.comparisons') }}</strong>
                                  <i class="icon dripicons-checkmark point"></i>
                                </a>
                              </li>
                              <li class="next-li buyer">
                                <a href="javascript:void(0)">
                                  <strong>{{ $t('lang.rates.tracker') }}</strong>
                                  <i class="icon dripicons-checkmark point"></i>
                                </a>
                              </li>
                              <li class="next-li buyer">
                                <a href="javascript:void(0)">
                                  <strong>{{ $t('lang.rates.reports') }}</strong>
                                  <i class="icon dripicons-checkmark point"></i>
                                </a>
                              </li>
                              <li class="next-li x">
                                <a href="javascript:void(0)">
                                  <strong>{{ $t('lang.rates.transfers') }}</strong>
                                  <i class="icon dripicons-plus point"></i>
                                </a>
                              </li>
                              <li class="next-li x">
                                <a href="javascript:void(0)">
                                  <strong>{{ $t('lang.rates.approvals') }}</strong>
                                  <i class="icon dripicons-plus point"></i>
                                </a>
                              </li>
                              <li class="next-li x">
                                <a href="javascript:void(0)">
                                  <strong>{{ $t('lang.rates.download_data') }}</strong>
                                  <i class="icon dripicons-plus point"></i>
                                </a>
                              </li>
                              <li class="next-li x">
                                <a href="javascript:void(0)">
                                  <strong>{{ $t('lang.rates.validation') }}</strong>
                                  <i class="icon dripicons-plus point"></i>
                                </a>
                              </li>
                              <li class="next-li x">
                                <a href="javascript:void(0)">
                                  <strong>{{ $t('lang.rates.user_areas') }}</strong>
                                  <i class="icon dripicons-plus point"></i>
                                </a>
                              </li>
                              <li class="next-li x">
                                <a href="javascript:void(0)">
                                  <strong>{{ $t('lang.rates.integration_with_ERP') }}</strong>
                                  <i class="icon dripicons-plus point"></i>
                                </a>
                              </li>
                              <li class="next-li x">
                                <a href="javascript:void(0)">
                                  <strong>{{ $t('lang.rates.RFI_request') }}</strong>
                                  <i class="icon dripicons-plus point"></i>
                                </a>
                              </li>
                            </ul>
                          </div>
                          <div class="handelbay-buttons">
                            <a :href="global.sign_up" class="btn btn-handelbay-planes">
                              {{ $t('lang.rates.i_want_this_plan') }}
                            </a>
                          </div>
                        </div>
                        <div class="col-plan-handelbay-1 active">
                          <div class="recommended">
                            <span>{{ $t('lang.rates.recommended') }}</span>
                          </div>
                          <div class="item text-center">
                            <strong>
                              <h4 class="plan_buyer_b" style="font-family: 'gotham_bold';">TEAM</h4>
                            </strong>
                            <br>
                            <strong>
                              {{ $t('lang.rates.5_users') }}
                            </strong>
                            <ul>
                              <li class="first-li">
                                <a href="javascript:void(0)">
                                  <strong>
                                    {{ $t('lang.rates.unlimited_processes') }}
                                  </strong>
                                </a>
                              </li>
                              <li class="next-li buyer">
                                <a href="javascript:void(0)">
                                  <strong>{{ $t('lang.rates.RFQ_request') }}</strong>
                                  <i class="icon dripicons-checkmark point"></i>
                                </a>
                              </li>
                              <li class="next-li buyer">
                                <a href="javascript:void(0)">
                                  <strong>{{ $t('lang.rates.comparisons') }}</strong>
                                  <i class="icon dripicons-checkmark point"></i>
                                </a>
                              </li>
                              <li class="next-li buyer">
                                <a href="javascript:void(0)">
                                  <strong>{{ $t('lang.rates.tracker') }}</strong>
                                  <i class="icon dripicons-checkmark point"></i>
                                </a>
                              </li>
                              <li class="next-li buyer">
                                <a href="javascript:void(0)">
                                  <strong>{{ $t('lang.rates.reports') }}</strong>
                                  <i class="icon dripicons-checkmark point"></i>
                                </a>
                              </li>
                              <li class="next-li buyer">
                                <a href="javascript:void(0)">
                                  <strong>{{ $t('lang.rates.transfers') }}</strong>
                                  <i class="icon dripicons-checkmark point"></i>
                                </a>
                              </li>
                              <li class="next-li buyer">
                                <a href="javascript:void(0)">
                                  <strong>{{ $t('lang.rates.approvals') }}</strong>
                                  <i class="icon dripicons-checkmark point"></i>
                                </a>
                              </li>
                              <li class="next-li x">
                                <a href="javascript:void(0)">
                                  <strong>{{ $t('lang.rates.download_data') }}</strong>
                                  <i class="icon dripicons-plus point"></i>
                                </a>
                              </li>
                              <li class="next-li x">
                                <a href="javascript:void(0)">
                                  <strong>{{ $t('lang.rates.validation') }}</strong>
                                  <i class="icon dripicons-plus point"></i>
                                </a>
                              </li>
                              <li class="next-li x">
                                <a href="javascript:void(0)">
                                  <strong>{{ $t('lang.rates.user_areas') }}</strong>
                                  <i class="icon dripicons-plus point"></i>
                                </a>
                              </li>
                              <li class="next-li x">
                                <a href="javascript:void(0)">
                                  <strong>{{ $t('lang.rates.integration_with_ERP') }}</strong>
                                  <i class="icon dripicons-plus point"></i>
                                </a>
                              </li>
                              <li class="next-li x">
                                <a href="javascript:void(0)">
                                  <strong>{{ $t('lang.rates.RFI_request') }}</strong>
                                  <i class="icon dripicons-plus point"></i>
                                </a>
                              </li>
                            </ul>
                          </div>
                          <div class="handelbay-buttons">
                            <a :href="global.sign_up" class="btn btn-handelbay-planes">
                              {{ $t('lang.rates.i_want_this_plan') }}
                            </a>
                          </div>
                        </div>
                        <div class="col-plan-handelbay-1">
                          <div class="item text-center">
                            <strong>
                              <h4 class="plan_buyer_b" style="font-family: 'gotham_bold';">PRO</h4>
                            </strong>
                            <br>
                            <strong>
                              {{ $t('lang.rates.30_users') }}
                            </strong>
                            <ul>
                              <li class="first-li">
                                <a href="javascript:void(0)">
                                  <strong>
                                    {{ $t('lang.rates.unlimited_processes') }}
                                  </strong>
                                </a>
                              </li>
                              <li class="next-li buyer">
                                <a href="javascript:void(0)">
                                  <strong>{{ $t('lang.rates.RFQ_request') }}</strong>
                                  <i class="icon dripicons-checkmark point"></i>
                                </a>
                              </li>
                              <li class="next-li buyer">
                                <a href="javascript:void(0)">
                                  <strong>{{ $t('lang.rates.comparisons') }}</strong>
                                  <i class="icon dripicons-checkmark point"></i>
                                </a>
                              </li>
                              <li class="next-li buyer">
                                <a href="javascript:void(0)">
                                  <strong>{{ $t('lang.rates.tracker') }}</strong>
                                  <i class="icon dripicons-checkmark point"></i>
                                </a>
                              </li>
                              <li class="next-li buyer">
                                <a href="javascript:void(0)">
                                  <strong>{{ $t('lang.rates.reports') }}</strong>
                                  <i class="icon dripicons-checkmark point"></i>
                                </a>
                              </li>
                              <li class="next-li buyer">
                                <a href="javascript:void(0)">
                                  <strong>{{ $t('lang.rates.transfers') }}</strong>
                                  <i class="icon dripicons-checkmark point"></i>
                                </a>
                              </li>
                              <li class="next-li buyer">
                                <a href="javascript:void(0)">
                                  <strong>{{ $t('lang.rates.approvals') }}</strong>
                                  <i class="icon dripicons-checkmark point"></i>
                                </a>
                              </li>
                              <li class="next-li buyer">
                                <a href="javascript:void(0)">
                                  <strong>{{ $t('lang.rates.download_data') }}</strong>
                                  <i class="icon dripicons-checkmark point"></i>
                                </a>
                              </li>
                              <li class="next-li buyer">
                                <a href="javascript:void(0)">
                                  <strong>{{ $t('lang.rates.validation') }}</strong>
                                  <i class="icon dripicons-checkmark point"></i>
                                </a>
                              </li>
                              <li class="next-li buyer">
                                <a href="javascript:void(0)">
                                  <strong>{{ $t('lang.rates.user_areas') }}</strong>
                                  <i class="icon dripicons-checkmark point"></i>
                                </a>
                              </li>
                              <li class="next-li buyer">
                                <a href="javascript:void(0)">
                                  <strong>{{ $t('lang.rates.integration_with_ERP') }}</strong>
                                  <i class="icon dripicons-checkmark point"></i>
                                </a>
                              </li>
                              <li class="next-li buyer">
                                <a href="javascript:void(0)">
                                  <strong>{{ $t('lang.rates.RFI_request') }}</strong>
                                  <i class="icon dripicons-checkmark point"></i>
                                </a>
                              </li>
                            </ul>
                          </div>
                          <div class="handelbay-buttons">
                            <a :href="global.sign_up" class="btn btn-handelbay-planes">
                              {{ $t('lang.rates.i_want_this_plan') }}
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div role="tabpanel" class="tab-pane active" id="provider">
                      <div class="title-tab text-center title-type-plan">
                        <p class="text-center">
                          {{ $t('lang.rates.supplier_profile') }}
                        </p>
                      </div>
                      <hr class="hr-space-planes">
                      <h2 class="phone-prov" style="text-align:center; font-family: 'gotham_bold';">
                        <a :href="'https://web.whatsapp.com/send?phone=' + global.indicative + global.cellphone">
                          <i class="fa fa-whatsapp" style="color:green"></i>
                          {{global.cellphone}}
                        </a>
                      </h2>
                      <hr class="hr-space-planes">
                      <div class="row-handelbay-plannes provider-row">
                        <div class="col-plan-handelbay-1">
                          <div class="item text-center">
                            <strong>
                              <h4 class="plan_prov" style="font-family: 'gotham_bold';">
                              {{ $t('lang.rates.free') }}
                            </h4>
                            </strong>
                            <span></span>
                            <strong>{{ $t('lang.rates.1_user') }}</strong>
                            <ul>
                              <li class="first-li">
                                <a href="javascript:void(0)">
                                  <strong>
                                    {{ $t('lang.rates.6_Quotes_/_month') }}
                                  </strong>
                                </a>
                              </li>
                              <li class="next-li provider">
                                <a href="javascript:void(0)">
                                  <strong>
                                    {{ $t('lang.rates.reception_of_invitations') }}
                                  </strong>
                                  <i class="icon dripicons-checkmark point"></i>
                                </a>
                              </li>
                              <li class="next-li provider">
                                <a href="javascript:void(0)">
                                  <strong>
                                    {{ $t('lang.rates.bulk_portfolio_upload') }}
                                  </strong>
                                  <i class="icon dripicons-checkmark point"></i>
                                </a>
                              </li>
                              <li class="next-li x">
                                <a href="javascript:void(0)">
                                  <strong>
                                    {{ $t('lang.rates.company_verification') }}
                                  </strong>
                                  <i class="icon dripicons-plus point"></i>
                                </a>
                              </li>
                              <li class="next-li x">
                                <a href="javascript:void(0)">
                                  <strong>
                                    {{ $t('lang.rates.secop') }}
                                  </strong>
                                  <i class="icon dripicons-plus point"></i>
                                </a>
                              </li>
                              <li class="next-li x">
                                <a href="javascript:void(0)">
                                  <strong>
                                    {{ $t('lang.rates.support') }}
                                  </strong>
                                  <i class="icon dripicons-plus point"></i>
                                </a>
                              </li>
                              <li class="next-li x">
                                <a href="javascript:void(0)">
                                  <strong>{{ $t('lang.rates.reports') }}</strong>
                                  <i class="icon dripicons-plus point"></i>
                                </a>
                              </li>
                              <li class="next-li x">
                                <a href="javascript:void(0)">
                                  <strong>
                                    {{ $t('lang.rates.process_transfer') }}
                                  </strong>
                                  <i class="icon dripicons-plus point"></i>
                                </a>
                              </li>
                              <li class="next-li x">
                                <a href="javascript:void(0)">
                                  <strong>
                                    {{ $t('lang.rates.KAM_model') }}
                                  </strong>
                                  <i class="icon dripicons-plus point"></i>
                                </a>
                              </li>
                            </ul>
                          </div>
                          <div class="handelbay-buttons">
                            <a :href="global.sign_up" class="btn btn-handelbay-planes">
                              {{ $t('lang.rates.i_want_this_plan') }}
                            </a>
                          </div>
                        </div>
                        <div class="col-plan-handelbay-1">
                          <div class="recommended">
                            <span>
                              {{ $t('lang.rates.recommended') }}
                            </span>
                          </div>
                          <div class="item text-center">
                            <strong>
                              <h4 class="plan_prov" style="font-family: 'gotham_bold';">
                                {{ $t('lang.rates.basic') }}
                              </h4>
                            </strong>
                            <span></span>
                            <strong>
                              {{ $t('lang.rates.2_users') }}
                            </strong>
                            <ul>
                              <li class="first-li">
                                <a href="javascript:void(0)">
                                  <strong>
                                    {{ $t('lang.rates.unlimited_quotes') }}
                                  </strong>
                                </a>
                              </li>
                              <li class="next-li provider">
                                <a href="javascript:void(0)">
                                  <strong>
                                    {{ $t('lang.rates.reception_of_invitations') }}</strong>
                                  <i class="icon dripicons-checkmark point"></i>
                                </a>
                              </li>
                              <li class="next-li provider">
                                <a href="javascript:void(0)">
                                  <strong>{{ $t('lang.rates.bulk_portfolio_upload') }}</strong>
                                  <i class="icon dripicons-checkmark point"></i>
                                </a>
                              </li>
                              <li class="next-li provider">
                                <a href="javascript:void(0)">
                                  <strong>{{ $t('lang.rates.company_verification') }}</strong>
                                  <i class="icon dripicons-checkmark point"></i>
                                </a>
                              </li>
                              <li class="next-li provider">
                                <a href="javascript:void(0)">
                                  <strong>{{ $t('lang.rates.secop') }}</strong>
                                  <i class="icon dripicons-checkmark point"></i>
                                </a>
                              </li>
                              <li class="next-li provider">
                                <a href="javascript:void(0)">
                                  <strong>{{ $t('lang.rates.support') }}</strong>
                                  <i class="icon dripicons-checkmark point"></i>
                                </a>
                              </li>
                              <li class="next-li x">
                                <a href="javascript:void(0)">
                                  <strong>{{ $t('lang.rates.reports') }}</strong>
                                  <i class="icon dripicons-plus point"></i>
                                </a>
                              </li>
                              <li class="next-li x">
                                <a href="javascript:void(0)">
                                  <strong>{{ $t('lang.rates.process_transfer') }}</strong>
                                  <i class="icon dripicons-plus point"></i>
                                </a>
                              </li>
                              <li class="next-li x">
                                <a href="javascript:void(0)">
                                  <strong>{{ $t('lang.rates.KAM_model') }}</strong>
                                  <i class="icon dripicons-plus point"></i>
                                </a>
                              </li>
                            </ul>
                          </div>
                          <div class="handelbay-buttons">
                            <a :href="global.sign_up" class="btn btn-handelbay-planes">
                              {{ $t('lang.rates.i_want_this_plan') }}
                            </a>
                          </div>
                        </div>
                        <div class="col-plan-handelbay-1">
                          <div class="item text-center">
                            <strong>
                              <h4 class="plan_prov" style="font-family: 'gotham_bold';">
                                {{ $t('lang.rates.team') }}
                              </h4>
                            </strong>
                            <span></span>
                            <strong>
                              {{ $t('lang.rates.10_users') }}
                            </strong>
                            <ul>
                              <li class="first-li">
                                <a href="javascript:void(0)">
                                  <strong>{{ $t('lang.rates.unlimited_quotes') }}</strong>
                                </a>
                              </li>
                              <li class="next-li provider">
                                <a href="javascript:void(0)">
                                  <strong>{{ $t('lang.rates.reception_of_invitations') }}</strong>
                                  <i class="icon dripicons-checkmark point"></i>
                                </a>
                              </li>
                              <li class="next-li provider">
                                <a href="javascript:void(0)">
                                  <strong>{{ $t('lang.rates.bulk_portfolio_upload') }}</strong>
                                  <i class="icon dripicons-checkmark point"></i>
                                </a>
                              </li>
                              <li class="next-li provider">
                                <a href="javascript:void(0)">
                                  <strong>{{ $t('lang.rates.company_verification') }}</strong>
                                  <i class="icon dripicons-checkmark point"></i>
                                </a>
                              </li>
                              <li class="next-li provider">
                                <a href="javascript:void(0)">
                                  <strong>{{ $t('lang.rates.secop') }}</strong>
                                  <i class="icon dripicons-checkmark point"></i>
                                </a>
                              </li>
                              <li class="next-li provider">
                                <a href="javascript:void(0)">
                                  <strong>{{ $t('lang.rates.support') }}</strong>
                                  <i class="icon dripicons-checkmark point"></i>
                                </a>
                              </li>
                              <li class="next-li provider">
                                <a href="javascript:void(0)">
                                  <strong>{{ $t('lang.rates.reports') }}</strong>
                                  <i class="icon dripicons-checkmark point"></i>
                                </a>
                              </li>
                              <li class="next-li provider">
                                <a href="javascript:void(0)">
                                  <strong>{{ $t('lang.rates.process_transfer') }}</strong>
                                  <i class="icon dripicons-checkmark point"></i>
                                </a>
                              </li>
                              <li class="next-li provider">
                                <a href="javascript:void(0)">
                                  <strong>{{ $t('lang.rates.KAM_model') }}</strong>
                                  <i class="icon dripicons-checkmark point"></i>
                                </a>
                              </li>
                            </ul>
                          </div>
                          <div class="handelbay-buttons">
                            <a :href="global.sign_up" class="btn btn-handelbay-planes">
                              {{ $t('lang.rates.i_want_this_plan') }}
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                    <hr class="hr-space-step">
                    <div class="button-footer-plan text-center">
                      <a :href="global.sign_up" class="btn btn-handelbay-success btn-handelbay-buyer" id="success">
                        {{ $t('lang.rates.continue') }}
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- .row -->
          </div>
        </div>
        <!-- handelbay-content-planes -->
      </div>
    </section>
    <!-- END TARIFAS -->

    <!-- PRUEBA GRATIS -->
    <section id="free-trial">
      <div class="container">
        <div class="row">
          <div class="col-md-5 col-sm-5 col-md-offset-1">
            <div class="title" itemscope itemtype="https://handelbay.com/">
              <h2 class="title-1" itemprop="HandelBay Innvador">
                {{ $t('lang.rates.start_your_period') }}
              </h2>
              <h3 class="title-2" itemprop="HandelBay en tu día a día">
                {{ $t('lang.rates.free_trial') }}
                
              </h3>
              <span class="handelbay-active"></span>
            </div>
            <div class="handelbay-text" itemscope itemtype="https://handelbay.com/">
              <p itemprop="HandelBay es la solución">
                {{ $t('lang.rates.try_HandelBay_for_free_and_access_the_Enterprise_plan_with_all_the_benefits_of_the_business_network') }} 
                {{ $t('lang.rates.you_do_not_require_a_credit_card_and_you_can_cancel_at_any_time') }}
              </p>
            </div>
            <div class="title-footer text-left">
              <a :href="global.sign_up" class="btn btn-handelbay-success btn-handelbay-black">
              {{ $t('lang.rates.sign_up') }}
              </a>
            </div>
          </div>
        </div>
        <!-- .row -->
      </div>
    </section>
    <!-- END PRUEBA GRATIS -->

    <!-- PREGUNTAS FRECUENTES -->
    <section id="frequent-questions">
      <div class="container">
        <div class="row">
          <div class="col-md-10 col-sm-12 col-md-offset-1 text-left">
            <div class="title" itemscope itemtype="https://handelbay.com/">
              <h2 class="title-2" itemprop="HandelBay en tu día a día">
                {{ $t('lang.rates.drequent_questions') }}
                <br>
                {{ $t('lang.rates.about_rates') }}
              </h2>
              <span class="handelbay-active"></span>
            </div>
            <div class="handelbay-text" itemscope itemtype="https://handelbay.com/">
              <div class="row">
                <div class="col-md-6 col-sm-6 col-xs-12">
                  <p itemprop="HandelBay es la solución" style="margin-bottom:10px;">
                    <strong style="">
                      {{ $t('lang.rates.what_features_does_the_free_trial_contain?') }}
                    </strong>
                    <br>
                    {{ $t('lang.rates.try_HandelBay_for_free_and_access_the_Enterprise_plan_with_all_the_benefits_of_the_business_network_for_15_days') }} 
                    {{ $t('lang.rates.you_do_not_require_a_credit_card_and_you_can_cancel_at_any_time') }}
                  </p>
                  <a :href="global.soporte" style="color:#30e7a3">
                    {{ $t('lang.rates.see_more') }}
                  </a>
                </div>
                <div class="col-md-6 col-sm-6 col-xs-12">
                  <p itemprop="HandelBay es la solución" style="margin-bottom:10px;">
                    <strong style="">
                      {{ $t('lang.rates.what_are_the_benefits_of_acquiring_the_Dual_profile?') }}
                    </strong>
                    <br>
                    {{ $t('lang.rates.when_purchasing_a_dual_profile_you_must_select_a_buyer_plan_and_another_for_a_provider_plan') }} 
                    {{ $t('lang.rates.HandelBay_will_automatically_make_the_50%_discount_on_the_lowest_value_plan') }}
                  </p>
                  <a :href="global.soporte" style="color:#30e7a3">{{ $t('lang.rates.see_more') }}</a>
                </div>
                <div class="col-md-6 col-sm-6 col-xs-12">
                  <p itemprop="HandelBay es la solución" style="margin-bottom:10px;">
                    <strong style="">
                      {{ $t('lang.rates.can_I_modify_my_payment_plan?') }} 
                    </strong>
                    <br>
                    {{ $t('lang.rates.yes') }} 
                    {{ $t('lang.rates.upgrading_the_plan_increases_the_benefits_that_HandelBay_offers_you') }} 
                    {{ $t('lang.rates.such_as_number_of_users') }} 
                    {{ $t('lang.rates.posts/quotes') }} 
                    {{ $t('lang.rates.besides_the_price') }} 
                    {{ $t('lang.rates.if_you_want_a_plan_with_fewer_benefits') }} 
                    {{ $t('lang.rates.you_must_deactivate_some_users') }} 
                    {{ $t('lang.rates.have_no_processes_in_open_and/or_running_state') }}
                  </p>
                  <a :href="global.soporte" style="color:#30e7a3">{{ $t('lang.rates.see_more') }}</a>
                </div>
                <div class="col-md-6 col-sm-6 col-xs-12">
                  <p itemprop="HandelBay es la solución" style="margin-bottom:10px;">
                    <strong style="">
                      {{ $t('lang.rates.are_there_permanence_clauses_within_HandelBay?') }}
                    </strong>
                    <br>
                    {{ $t('lang.rates.no?') }} 
                    {{ $t('lang.rates.you_are_free_to_withdraw_when_you_consider_it_necessary') }} 
                    {{ $t('lang.rates.at_HandelBay_you_have_the_right_to_stay_the_time_you_have_paid_for') }} 
                    {{ $t('lang.rates.you_can_withdraw_at_any_time_but_your_money_is_not_refunded') }}
                  </p>
                  <a :href="global.soporte" style="color:#30e7a3">{{ $t('lang.rates.see_more') }}</a>
                </div>
              </div>
              <!-- .row -->
              <div class="button-questions text-center">
                <a :href="global.soporte" class="btn btn-handelbay-success btn-questions">{{ $t('lang.rates.see_more') }} 
                {{ $t('lang.rates.frequent_questions') }}
              </a>
              </div>
            </div>
          </div>
        </div>
        <!-- .row -->
      </div>
    </section>
    <!-- END PREGUNTAS FRECUENTES -->

    <!-- .handelbay-background-curva -->
    <section id="handelbay-background-curva-bottom">
    </section>
    <!-- .handelbay-background-curva -->

    <PorqueHandelbay />
    <ModalPlans />

  </div>
</template>
<script>
import global from '@/components/Global.vue'
import PorqueHandelbay from "@/components/PorqueHandelbay.vue"
import ModalPlans from "@/components/ModalPlans.vue"

export default {
  components:{
    PorqueHandelbay,
    ModalPlans
  },
  methods:{
    change_month: (id) =>{
      $(".plan").removeClass("active")
      $(".btn-time").removeClass("active")
      switch(id){
        case 1:
          $(".plan_mensual").addClass("active")
          $(".btn-time.mes").addClass("active")
          break
        case 2:
          $(".plan_semestral").addClass("active")
          $(".btn-time.semestre").addClass("active")
          break
        case 3:
          $(".plan_anual").addClass("active")
          $(".btn-time.anual").addClass("active")
          break
      }
    },
    change_plan: (id) =>{
      if(id == 2){
        $(".tab-pane").addClass("active")
      }
    }

  },
  data(){
    return{
      global: global
    }
  }
}
</script>
<style>
.plan_mensual,.plan_semestral,.plan_anual{
  display:none;
}
.plan_mensual.active,.plan_semestral.active,.plan_anual.active{
  display:block;
}
#tarifas .handelbay-content-planes .months_plan.months.buyer .btn-time{
  color: #30e7a3;
  border: 1px solid #30e7a3;
}
#tarifas .handelbay-content-planes .months_plan.months.buyer .btn-time.active{
  background-color: #30e7a3;
  color:#fff;
}
#tarifas .handelbay-content-planes .months_plan.months.provider .btn-time{
  color: #36aeea;
  border: 1px solid #36aeea;
}
#tarifas .handelbay-content-planes .months_plan.months.provider .btn-time.active{
  background-color: #36aeea;
  color:#fff;
}	
</style>